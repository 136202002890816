<template>
  <div>
    <LoadingOverlay v-if="isUploading" />
    <ValidationObserver ref="register" v-slot="{ handleSubmit }">
      <form
        id="register"
        @submit.prevent="handleSubmit()"
        v-on:keypress.enter.prevent
        autocomplete="off"
      >
        <CCard>
          <CCardBody>
            <CRow name="personal-details">
              <h4 class="pb-3 d-block d-md-none main-title text-primary">
                Personal Details
              </h4>
              <div class="col-md-5 col-lg-4 col-xl-3 mb-3">
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    <div :style="{ backgroundImage: 'url(' + url + ')' }"></div>
                  </div>
                  <div class="avatar-edit">
                    <input
                      id="imageUpload"
                      type="file"
                      ref="profilefileref"
                      name="image"
                      accept="image/*"
                      @change="selectedFile"
                    />
                    <label
                      for="imageUpload"
                      class="p-2 text-primary font-weight-bold"
                      >Upload Photo</label
                    >
                  </div>
                  <div v-if="isImageSelected">
                    <UploadDocument
                      class="mt-4"
                      :fileData="fileData"
                      v-on="$listeners"
                      :isProfilePic="true"
                      @closeModal="closeModal"
                      @upload="upload"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-lg-8 col-xl-9">
                <h4
                  class="pb-3 main-title text-primary d-none d-md-block d-md-none"
                >
                  Personal Details
                </h4>
                <CRow name="title">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12">Title</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="title_id"
                            @input="handleChangeSelect"
                            :value="candidate.title_id"
                            :options="(options && options['titles']) || []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="first_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >First Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="first_name"
                            :value="candidate.first_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow name="middlename" class="mb-3">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12">Middle Name(s)</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="middle_name"
                            :value="candidate.middle_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="surname" class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Last Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="surname"
                            :value="candidate.surname"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" lg="6">
                    <CRow name="date_of_birth" class="row pb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Date of Birth</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                        <ValidationProvider
                          name="dob"
                          rules="required|date_validate|no_future_date"
                          v-slot="{ errors }"
                        >
                          <DateInput
                            :onlyMonthAndYear="false"
                            name="dob"
                            :value="candidate.dob"
                            @input="handleDatePickerChange"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="gender" class="row mb-3">
                      <label class="required col-lg-12 col-md-12">Gender</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="gender_id"
                            :value="candidate.gender_id"
                            @input="handleChangeSelect"
                            :options="(options && options['gender']) || []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" lg="6">
                    <CRow name="nationality" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Nationality</label
                      >
                      <div class="col infoicon-multi-select">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            :name="`nationality_ids`"
                            @input="handleChangeMultiSelectAsArray"
                            :value="candidate.nationality_ids"
                            :options="(options && options['nationality']) || []"
                            option_label="nationality"
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="position-relative multi-select-icon-div">
                        <CIcon
                          name="cil-info"
                          class="hinticon-multi-select"
                          v-c-tooltip="'Select one or more'"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="currently-working-in" class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Current Residency</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <Select
                          :name="`country_id`"
                          @input="handleChangeSelect"
                          :value="candidate.country_id"
                          :options="(options && options['locationInfo']) || []"
                          :taggable="false"
                          :multiple="false"
                          :clearable="true"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody name="contact-information">
            <h4 class="main-title text-primary pb-3">Contact Information</h4>
            <CRow>
              <CCol md="6">
                <CRow name="phone" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Phone Number</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="candidate.phone"
                        :options="(options && options['dialingCode']) || []"
                        @change="handleInput"
                        :error="errors[0]"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CRow
                  name="isRegisteredWhatsapp"
                  class="row mb-3 registered-whatsapp"
                >
                  <label class="col-lg-5 col-md-5 col-8 registered-whatsapp"
                    >Registered in WhatsApp</label
                  >
                  <CSwitch
                    name="isRegisteredWhatsapp"
                    color="success"
                    :checked="candidate.isRegisteredWhatsapp"
                    shape="pill"
                    variant="3d"
                    :disabled="
                      candidate.preferred_contact_method.some(
                        (el) => el.id == 'whatsapp'
                      )
                    "
                    class="ml-2 registered-whatsapp"
                    @change.native="
                      handleChangeCheck('isRegisteredWhatsapp', $event)
                    "
                  />
                </CRow>
              </CCol>
              <CCol v-if="candidate.isRegisteredWhatsapp" md="6">
                <CRow name="whatsapp" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">WhatsApp</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="whatsapp"
                      :rules="{
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        required: true,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="whatsapp"
                        :value="candidate.whatsapp"
                        :options="(options && options['dialingCode']) || []"
                        @change="handleInput"
                        :error="errors[0]"
                        ref="whatsappNo"
                      />
                      <small class="hint">
                        Please provide the phone number registered with WhatsApp
                        here.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="preferred_contact_method" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Preferred Contact Method</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      name="preferred_contact_method"
                      :value="candidate.preferred_contact_method"
                      :options="
                        (options && options['preferred_contact_method']) || []
                      "
                      @input="handleChangeSelect"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="email" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="alternate_email"
                      ref="alternate_email"
                      rules="email|required"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="alternate_email"
                        :value="candidate.alternate_email"
                        @change="handleEmailCheck"
                        @input="smallCaseConvertion"
                        :error="errors[0]"
                        autocomplete="new-email"
                        :placeholder="
                          !isCandidateSupplier
                            ? `Retype your email address here`
                            : 'Enter Candidate email address here'
                        "
                        :disabled="!isCandidateSupplier"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard v-if="!isNurse && !isAHP && !isStudentQualified">
          <CCardBody>
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol>
                <CRow name="currently" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >I am currently a</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_student"
                        :value="candidate.is_student"
                        :vertically="true"
                        :options="filterCurrentlyOptions || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Profession or Field of Study
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="(options && options['candidateType']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNonClinicAdmin">
                <CRow name="level" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">Level</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_id"
                        :value="candidate.level_id"
                        @input="handleChangeSelect"
                        :options="(options && options['candidateLevel']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :showTooltip="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNonClinicAdmin">
                <CRow name="current-job-title" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Current Job Title / Role</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="current_job"
                      :value="candidate.current_job"
                      @change="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol v-if="isNonClinicAdmin" md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12">
                    Years in Role (<b v-if="candidate.years_at_level != null">{{
                      candidate.years_at_level + " "
                    }}</b
                    >yrs)
                  </label>
                  <div
                    class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12 years_in_role"
                  >
                    <v-slider
                      name="years_in_role"
                      :min="0"
                      :max="20"
                      :value="candidate.years_at_level"
                      v-model="candidate.years_at_level"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol v-if="isPhysician" md="12">
                <h5 class="main-title text-primary py-2">
                  First/Primary Medical Qualification
                </h5>
                <CRow
                  name="month-and-year-first-medical-degree-obtained"
                  class="row mb-3"
                >
                  <label class="col-lg-12 col-md-12 required"
                    >Month / Year First Medical Degree Obtained
                  </label>
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <ValidationProvider
                      name="first_qualified_date"
                      rules="no_future_date|dob_check:@dob|required"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="first_qualified_date"
                        :value="candidate.first_qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="isPhysician"
                    class="position-relative multi-select-icon-div col-lg-6 col-md-6 col-sm-6"
                  >
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="{
                        content: 'eg: MBBS or MD, etc',
                        appendToBody: true,
                      }"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol v-if="isPhysician" md="12">
                <h5 class="main-title text-primary py-2">Specialisation</h5>
              </CCol>
              <CCol md="12" lg="6">
                <CRow name="highest-qualification-obtained-in" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">{{
                    isPhysician
                      ? "Highest Specialisation Qualification or Certificate Obtained In"
                      : "Highest Qualification Obtained In"
                  }}</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_country_id"
                        :value="candidate.main_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="(options && options['locationInfo']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="preferred_contact_method" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">{{
                    isNonClinicAdmin
                      ? "Highest Qualification"
                      : isPhysician
                      ? "Highest Specialisation Qualification or Certificate Name"
                      : "Qualification Name"
                  }}</label>
                  <div class="col infoicon-multi-select">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_id"
                        :value="candidate.main_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          isPhysician
                            ? (options && options['higherQualification']) || []
                            : (options && options['qualification']) || []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="isPhysician"
                    class="position-relative multi-select-icon-div"
                  >
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="{
                        appendToBody: true,
                        content:
                          'eg: MRCP or FRCS or CCT or Board Certification, etc',
                      }"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow>
                  <CCol lg="12" v-if="mainQualificationIsOther">
                    <CRow name="other_qualification_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Other Qualification ( Level / Subject )</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="other_qualification_name"
                            :value="candidate.other_qualification_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol
                    md="12"
                    v-if="
                      candidate.main_qualification_id &&
                      candidate.main_qualification_id.allow_subject_input ==
                        true
                    "
                  >
                    <CRow name="qualification_subject_name" class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Qualification Subject</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="main_qualification_description"
                          :value="candidate.main_qualification_description"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol
                    lg="12"
                    v-if="
                      candidate.main_qualification_id
                        ? candidate.main_qualification_id.by_exam
                        : false
                    "
                  >
                    <CRow class="row mb-3">
                      <label
                        name="lbl_by_exam"
                        class="required col-lg-12 col-md-12"
                        >Is This By Exam?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="main_qualification_by_exam"
                            :value="candidate.main_qualification_by_exam"
                            :options="(options && options['byExam']) || []"
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CRow name="month-and-year-awarded" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Month / Year Awarded
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="no_future_date|dob_check:@dob|required|higher_qual_date_check:@first_qualified_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="qualified_date"
                        :value="candidate.qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                md="6"
                v-if="
                  candidate.main_qualification_id &&
                  candidate.main_qualification_id.specialist_registration_reqd
                "
              >
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Are you on a Specialist Register?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="specialist_registration"
                        :value="candidate.specialist_registration"
                        :options="
                          (options && options['specialistRegister']) || []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="candidate.specialist_registration == true">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Acquired Date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|no_future_date|dob_check:@dob"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="acquired_date"
                        :value="acquired_date"
                        @input="handleAcquiredDate"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="!isNonClinicAdmin">
              <CCol md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Speciality (Licensed to practice as)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="(options && options['speciality']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="options['subSpeciality'].length">
                <CRow name="sub_speciality" class="row mb-3">
                  <label class="col-lg-12 col-md-12">Sub Speciality</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="sub_speciality_id"
                      :value="candidate.sub_speciality_id"
                      @input="handleChangeSelect"
                      :options="(options && options['subSpeciality']) || []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="isNonClinicAdmin">
              <CCol md="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Main Area of Expertise</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="(options && options['speciality']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Other areas of expertise<small
                      class="text-primary ml-1 font-weight-bold"
                      >(select max 3)</small
                    ></label
                  >
                  <div class="col-lg-12 col-md-12">
                    <Select
                      :name="`other_areas_of_expertise_ids`"
                      @input="handleChangeMultiSelectAsArray"
                      :value="candidate.other_areas_of_expertise_ids"
                      :options="(options && options['speciality']) || []"
                      option_label="label"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      :limit="3"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard v-if="isAHP && !isStudentQualified">
          <CCardBody name="professional-information">
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol>
                <CRow name="currently" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >I am currently a</label
                  >
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_student"
                        :value="candidate.is_student"
                        :vertically="true"
                        :options="filterCurrentlyOptions || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Profession or Field of Study
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="(options && options['candidateType']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow> </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="highest-qualification-obtained-in" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >{{
                      ahpStudentTrainee
                        ? "Currently studying in"
                        : "Highest Qualification Obtained In"
                    }}
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_country_id"
                        :value="candidate.main_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="(options && options['locationInfo']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="highest-qualification-name" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >{{ ahpStudentTrainee ? "" : "Highest" }} Qualification
                    Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_id"
                        :value="candidate.main_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          (options && options['AHPqualification']) || []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow>
                  <CCol lg="12" v-if="mainQualificationIsOther">
                    <CRow name="other_qualification_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Other Qualification ( Level / Subject )</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="other_qualification_name"
                            :value="candidate.other_qualification_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol
                    md="12"
                    v-if="
                      candidate.main_qualification_id &&
                      candidate.main_qualification_id.allow_subject_input ==
                        true
                    "
                  >
                    <CRow name="qualification_subject_name" class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Qualification Subject</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="main_qualification_description"
                          :value="candidate.main_qualification_description"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol
                    lg="12"
                    v-if="
                      candidate.main_qualification_id
                        ? candidate.main_qualification_id.by_exam
                        : false
                    "
                  >
                    <CRow class="row mb-3">
                      <label
                        name="lbl_by_exam"
                        class="required col-lg-12 col-md-12"
                        >Is This By Exam?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="main_qualification_by_exam"
                            :value="candidate.main_qualification_by_exam"
                            :options="(options && options['byExam']) || []"
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Course Duration (<b
                      v-if="
                        candidate.main_qualification_course_duration != null
                      "
                      >{{
                        candidate.main_qualification_course_duration + " "
                      }}</b
                    >yrs)
                  </label>
                  <div
                    class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12 main_qualification_course_duration"
                  >
                    <v-slider
                      name="main_qualification_course_duration"
                      :min="0"
                      :max="10"
                      :value="candidate.main_qualification_course_duration"
                      v-model="candidate.main_qualification_course_duration"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">{{
                    ahpStudentTrainee
                      ? "Expected Graduation/Award Date"
                      : "Month / Year Awarded"
                  }}</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="qualified_date"
                      :rules="`${
                        ahpStudentTrainee
                          ? 'required'
                          : 'no_future_date|dob_check:@dob|required'
                      }`"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        :futureYear="ahpStudentTrainee ? true : false"
                        name="qualified_date"
                        :value="candidate.qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                        :futureYearLimit="10"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Speciality (Licensed to practice as)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!ahpStudentTrainee">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Number of years in Speciality (<b
                      v-if="candidate.speciality_duration != null"
                      >{{ candidate.speciality_duration + " " }}</b
                    >yrs)
                  </label>
                  <div
                    class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12 speciality_duration"
                  >
                    <v-slider
                      name="speciality_duration"
                      :min="0"
                      :max="20"
                      :value="candidate.speciality_duration"
                      v-model="candidate.speciality_duration"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard v-if="isNurse && !isStudentQualified">
          <CCardBody name="professional-information">
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol>
                <CRow name="currently" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >I am currently a</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_student"
                        :value="candidate.is_student"
                        :vertically="true"
                        :options="filterCurrentlyOptions || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Profession or Field of Study
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="(options && options['candidateType']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNurse">
                <CRow name="nurse-widwifery-level" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Nurse / Midwifery Level</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_id"
                        :value="candidate.level_id"
                        @input="handleChangeSelect"
                        :options="(options && options['candidateLevel']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :showTooltip="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="first-qualification-obtained-in" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">{{
                    isStudentLevel
                      ? "Currently studying in"
                      : "First Qualification Obtained In"
                  }}</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="first_qualification_country_id"
                        :value="candidate.first_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="(options && options['locationInfo']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="
                  isNurse &&
                  candidate.first_qualification_country_id &&
                  candidate.first_qualification_country_id.code === 182
                "
              >
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12">Current NHS Band?</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="band_id"
                      :value="candidate.band_id"
                      @input="handleChangeSelect"
                      :options="(options && options['candidateBands']) || []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="first-qualification-name" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >{{ isStudentLevel ? "" : "First" }} Qualification
                    Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="first_qualification_id"
                        :value="candidate.first_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          (options && options['firstQualification']) || []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="
                  candidate.first_qualification_id &&
                  candidate.first_qualification_id.allow_subject_input == true
                "
              >
                <CRow name="first_qualification_subject_name" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Qualification Subject</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_qualification_description"
                        :value="candidate.first_qualification_description"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6" v-if="firstQualificationIsOther">
                <CRow name="first_other_qualification_name" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Qualification ( Level / Subject )</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_other_qualification_name"
                        :value="candidate.first_other_qualification_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                lg="6"
                v-if="
                  candidate.first_qualification_id
                    ? candidate.first_qualification_id.by_exam
                    : false
                "
              >
                <CRow class="row mb-3">
                  <label name="lbl_by_exam" class="required col-lg-12 col-md-12"
                    >Is This By Exam?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="first_qualification_by_exam"
                        :value="candidate.first_qualification_by_exam"
                        :options="(options && options['byExam']) || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Course Duration (<b
                      v-if="
                        candidate.first_qualification_course_duration != null
                      "
                      >{{
                        candidate.first_qualification_course_duration + " "
                      }}</b
                    >yrs)
                  </label>
                  <div
                    class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12 first_course_duration"
                  >
                    <v-slider
                      name="first_qualification_course_duration"
                      :min="0"
                      :max="10"
                      :value="candidate.first_qualification_course_duration"
                      v-model="candidate.first_qualification_course_duration"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    {{
                      isStudentLevel
                        ? "Expected Graduation/Award Date"
                        : "Month / Year Awarded"
                    }}</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="first_qualified_date"
                      :rules="`${
                        isStudentLevel ? '' : 'no_future_date|dob_check:@dob|'
                      }required`"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        :futureYear="isStudentLevel ? true : false"
                        name="first_qualified_date"
                        :value="candidate.first_qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                        :futureYearLimit="10"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="border-top-primary-3 pt-3" v-if="!isStudentLevel">
              <CCol lg="6">
                <CRow class="row mb-3">
                  <label
                    name="lbl_have_higher_qualification"
                    class="required col-lg-12 col-md-12"
                    >Have you a Higher Medical Qualification?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_have_higher_qualification"
                        :value="candidate.is_have_higher_qualification"
                        :options="
                          (options && options['haveHigherQualification']) || []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div v-if="payload.is_have_higher_qualification">
              <CRow>
                <CCol md="6">
                  <CRow
                    name="highest-qualification-obtained-in"
                    class="row mb-3"
                  >
                    <label class="col-lg-12 col-md-12 required"
                      >Highest Qualification Obtained In</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="main_qualification_country_id"
                          :value="candidate.main_qualification_country_id"
                          @input="handleChangeSelect"
                          :options="(options && options['locationInfo']) || []"
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6"> </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CRow name="qualification-name" class="row mb-3">
                    <label
                      class="col-lg-12 col-md-12 required main_qualification"
                      >Qualification Name</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="main_qualification_id"
                          :value="candidate.main_qualification_id"
                          @input="handleChangeSelect"
                          :options="
                            isNurse
                              ? options['nurseHigherQualification']
                              : options['qualification']
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" v-if="mainQualificationIsOther">
                  <CRow name="main_other_qualification_name" class="row mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >Other Qualification ( Level / Subject )</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="other_qualification_name"
                          :value="candidate.other_qualification_name"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  md="6"
                  v-if="
                    candidate.main_qualification_id &&
                    candidate.main_qualification_id.allow_subject_input == true
                  "
                >
                  <CRow name="qualification_subject_name" class="row mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Qualification Subject</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="main_qualification_description"
                          :value="candidate.main_qualification_description"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label class="col-lg-12 col-md-12 required">
                      Course Duration (<b
                        v-if="
                          candidate.main_qualification_course_duration != null
                        "
                        >{{
                          candidate.main_qualification_course_duration + " "
                        }}</b
                      >yrs)
                    </label>
                    <div
                      class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12 course_duration"
                    >
                      <v-slider
                        name="main_qualification_course_duration"
                        :min="0"
                        :max="10"
                        :value="candidate.main_qualification_course_duration"
                        v-model="candidate.main_qualification_course_duration"
                      ></v-slider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Month / Year Awarded</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        rules="no_future_date|dob_check:@dob|required|higher_qual_date_check:@first_qualified_date"
                        v-slot="{ errors }"
                      >
                        <DateInput
                          :onlyMonthAndYear="true"
                          name="qualified_date"
                          :value="candidate.qualified_date"
                          @change="handleDatePickerChange"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <CRow
              v-if="
                isNurse &&
                payload.is_have_higher_qualification &&
                isPGMainQualification
              "
            >
              <CCol md="6">
                <div name="row_area_of_practice" class="mb-3">
                  <label
                    name="lbl_area_of_practice"
                    class="d-flex justify-content-between"
                  >
                    <span>
                      <label class="required">
                        Are any of your Higher Qualifications related to your
                        area of practice?
                      </label>
                    </span>
                  </label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <RadioButton
                      class="text-nowrap"
                      name="any_higherqualification_related_to_practice"
                      :value="
                        candidate.any_higherqualification_related_to_practice
                      "
                      :options="(options && options['boolean']) || []"
                      @change="handleChangeRadio"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" v-if="!isStudentLevel">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >{{
                      isNurse
                        ? "Main Speciality"
                        : "Speciality (Licensed to practice as)"
                    }}
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          options && isNurse
                            ? options['nurseSubSpeciality']
                            : options['speciality']
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!isStudentLevel">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Number of years in Speciality (<b
                      v-if="candidate.speciality_duration != null"
                      >{{ candidate.speciality_duration + " " }}</b
                    >yrs)
                  </label>
                  <div
                    class="ml-1 mr-1 col-lg-12 col-md-12 col-sm-12 speciality_duration"
                  >
                    <v-slider
                      name="speciality_duration"
                      :min="0"
                      :max="20"
                      :value="candidate.speciality_duration"
                      v-model="candidate.speciality_duration"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNurse && !isStudentLevel">
                <CRow name="licensed-to-practice-in" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Licensed to Practice In</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="license_country_id"
                      :value="candidate.license_country_id"
                      @input="handleChangeSelect"
                      :options="(options && options['locationInfo']) || []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :showTooltip="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol v-if="isNurse && !isStudentLevel" md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Licensed to Practice as (Please select as stated on your
                    license)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="license_level_id"
                      :value="candidate.license_level_id"
                      @input="handleChangeSelect"
                      :options="
                        (options &&
                          options['candidateLicenseLevelByCountry']) ||
                        []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                md="6"
                v-if="
                  isNurse &&
                  payload.is_have_higher_qualification &&
                  payload.any_higherqualification_related_to_practice
                "
              >
                <CRow name="registered_as" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Are you registered as a</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="d-flex justify-content-between">
                      <label
                        >Speciality Nurse or a Nurse(Advanced Nurse)
                        Practitioner?</label
                      >
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <RadioButton
                          class="text-nowrap"
                          name="speciality_nurse_or_nurse_practitioner"
                          :value="
                            candidate.speciality_nurse_or_nurse_practitioner
                          "
                          :options="(options && options['boolean']) || []"
                          @change="handleChangeRadio"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNurse && !isStudentLevel">
                <CRow name="other-areas-of-expertise" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Other areas of expertise<small
                      class="text-primary ml-1 font-weight-bold"
                      >(select max 3)</small
                    ></label
                  >
                  <div class="col-lg-12 col-md-12">
                    <Select
                      :name="`other_areas_of_expertise_ids`"
                      @input="handleChangeMultiSelectAsArray"
                      :value="candidate.other_areas_of_expertise_ids"
                      :options="
                        (options && options['nurseSubSpeciality']) || []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      :limit="3"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isAHP && options['subSpeciality'].length">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Sub Type / Licensed as</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      :name="`sub_speciality_id`"
                      @input="handleChangeSelect"
                      :value="candidate.sub_speciality_id"
                      :options="(options && options['subSpeciality']) || []"
                      option_label="label"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isOther">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Other areas of expertise Name</label
                  >
                  <div class="col-lg-12 col-md-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_areas_of_expertise_name"
                        @input="handleInput"
                        :value="candidate.other_areas_of_expertise_name"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard v-if="isStudentQualified">
          <CCardBody>
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol>
                <CRow name="currently" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >I am currently a</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_student"
                        :value="candidate.is_student"
                        :vertically="true"
                        :options="filterCurrentlyOptions || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Profession or Field of Study
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="(options && options['candidateType']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="isNonHealthcare"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!isEducationalInstitute">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Where are you studying?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="student_country_id"
                        :value="candidate.student_country_id"
                        @input="handleChangeSelect"
                        :options="(options && options['locationInfo']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="(isNurse || isAHP) && !isEducationalInstitute">
                <CRow class="d-flex align-items-center mb-3">
                  <label name="sponsorship" class="col-lg-12 col-md-12 required"
                    >Training Area</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="student_speciality_id"
                        :value="candidate.student_speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          (options && options['studentCourseSpeciality']) || []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isEducationalInstitute">
                <CRow class="d-flex align-items-center mb-3">
                  <label
                    name="course_qualification"
                    class="col-lg-12 col-md-12 required"
                    >Select your Course</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                        name="student_training_course_id"
                        :value="candidate.student_training_course_id"
                        @input="handleChangeSelect"
                        :options="(options && options['course_list']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" v-if="!isEducationalInstitute">
                <CRow class="d-flex align-items-center mb-3">
                  <label
                    name="course_qualification"
                    class="col-lg-12 col-md-12 required"
                    >Course Level</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="student_qualification_id"
                        :value="candidate.student_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          (options && options['course_qualifications']) || []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row pb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Course Start Date
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                    <ValidationProvider
                      name="course_start_date"
                      rules="required|date_validate|no_future_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        name="course_start_date"
                        :value="candidate.course_start_date"
                        @input="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row pb-3">
                  <label class="required col-lg-12 col-md-12">
                    {{
                      isPhysician || isDentist
                        ? "Course Completion Date"
                        : "Course Completion Date"
                    }}
                  </label>
                  <div class="col infoicon-multi-select">
                    <ValidationProvider
                      name="course_end_date"
                      rules="required|date_validate|future_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        :futureYear="true"
                        :futureYearLimit="10"
                        name="course_end_date"
                        :value="candidate.course_end_date"
                        @input="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                  v-if="isPhysician || isDentist"
                  class="position-relative multi-select-icon-div"
                >
                  <CIcon
                  name="cil-info"
                  class="hinticon-multi-select"
                  v-c-tooltip="'Including Internship'"
                />
                </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="label_student_course" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Is your course?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="student_course_mode"
                        :value="candidate.student_course_mode"
                        :options="(options && options['modeOfCourse']) || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="label_student_course" class="row mb-3">
               
                  <label  class="col-lg-12 col-md-12">
                    Is your course Distance or Online Learning</label
                  >

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="student_distance_learning"
                      :value="candidate.student_distance_learning"
                      :options="(options && options['distance_online']) || []"
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label name="sponsorship" class="col-lg-12 col-md-12 required"
                    >Are you a sponsored student?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_sponsored"
                        :value="candidate.is_sponsored"
                        :options="(options && options['boolean']) || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="payload.is_sponsored">
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label
                    name="sponsorship_start_date_label"
                    class="col-lg-12 col-md-12"
                    >Sponsorship Start date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="sponsorship_start_date"
                      rules="date_validate|no_future_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        name="sponsorship_start_date"
                        :value="candidate.sponsorship_start_date"
                        @change="handleDatePickerChange"
                        :pastYearLimit="10"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label
                    name="sponsorship_end_date_label"
                    class="col-lg-12 col-md-12"
                    >Sponsorship End Date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="sponsorship_end_date"
                      :rules="{ end_date_greater: candidate.sponsorship_start_date }"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        :futureYear="true"
                        name="sponsorship_end_date"
                        :value="candidate.sponsorship_end_date"
                        @change="handleDatePickerChange"
                        :futureYearLimit="10"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard class="mt-0">
          <CCardBody>
            <h4 class="main-title text-primary mb-3">
              Language Training/Qualifications
            </h4>
            <CRow>
              <CCol md="12">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Are you studying for, or have obtained, any Language qualifications?
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_additional_course"
                        :value="candidate.is_additional_course"
                        :options="(options && options['boolean']) || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="isAdditionalCourse">
              <CCol md="6">
                <CRow name="course_name" class="row pb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Course Type
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="course_name"
                        :value="candidate.course_name"
                        @input="handleChangeSelect"
                        :options="
                          (options && options['course_test_type_vocational']) ||
                          []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label name="language_countries" class="col-lg-12 col-md-12"
                    >Language</label
                  >

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="language_id"
                      :value="candidate.language_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['languageList']
                          ? options['languageList']
                          : []
                      "
                    />
                  </div>
                </CRow>
              </CCol>
              
              <CCol md="6" v-if="payload.language_id === 1">
                <CRow class="d-flex align-items-center mb-3">

                  
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    
                    <RadioButton
                      name="language_options"
                      :value="candidate.language_options"
                      @change="handleChangeRadio"
                      :options="
                        options && options['languageListOptions']
                          ? options['languageListOptions']
                          : []
                      "
                    />
                   
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="candidate.language_options === 1 && candidate.is_additional_course">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label
                    name="language_exam_label"
                    class="col-lg-12 col-md-12 required"
                    >Course Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="language_exam"
                        :value="candidate.language_exam"
                        @input="handleChangeSelect"
                        :options="(options && options['languageExam']) || []"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow v-else-if="candidate.language_options === 2 && candidate.is_additional_course">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label
                    name="language_exam_label"
                    class="col-lg-12 col-md-12 required"
                    >Course Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="language_exam"
                        :value="candidate.language_exam"
                        @input="handleChangeSelect"
                        :options="(options && options['languageExam']) || []"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label name="sponsorship" class="col-lg-12 col-md-12 required"
                    >Is this course sponsored/funded?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_additional_training_sponsored"
                        :value="candidate.is_additional_training_sponsored"
                        :options="(options && options['boolean']) || []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>


            <CRow v-if="isAdditionalCourse">
              <CCol md="6">
                <CRow class="row pb-3">
                  <label class="col-lg-12 col-md-12">Course Start Date</label>
                  <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                    <ValidationProvider
                      name="training_course_start_date"
                      rules="date_validate"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        name="training_course_start_date"
                        :value="candidate.training_course_start_date"
                        @input="handleDatePickerChange"
                        :error="errors[0]"
                        :pastFutureYear=[14,2]
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!isStudentQualified">
                <CRow class="row pb-3">
                  <label class="col-lg-12 col-md-12">
                    Course Completion Date
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                    <ValidationProvider
                      name="training_course_end_date"
                      rules="date_validate|future_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        :futureYear="true"
                        :futureYearLimit="10"
                        name="training_course_end_date"
                        :value="candidate.training_course_end_date"
                        @input="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
          
            </CRow>
            <CRow v-if="payload.is_additional_course && payload.is_additional_training_sponsored && candidate.language_options === 2">
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label
                    name="sponsorship_start_date_label"
                    class="col-lg-12 col-md-12"
                    >Sponsorship Start Date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="sponsorship_start_date"
                      rules="date_validate|no_future_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        name="additional_sponsorship_start_date"
                        :value="candidate.additional_sponsorship_start_date"
                        @change="handleDatePickerChange"
                        :pastYearLimit="10"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="d-flex align-items-center mb-3">
                  <label
                    name="sponsorship_end_date_label"
                    class="col-lg-12 col-md-12"
                    >Sponsorship End Date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="sponsorship_end_date"
                      rules="date_validate|future_date"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="false"
                        :futureYear="true"
                        name="additional_sponsorship_end_date"
                        :value="candidate.additional_sponsorship_end_date"
                        @change="handleDatePickerChange"
                        :futureYearLimit="10"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody name="your-preferences">
            <h4 class="main-title text-primary pb-3">Your Preferences</h4>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Preferred Region(s)</label
                  >
                  <div class="col infoicon-multi-select">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="region_ids"
                        :value="candidate.region_ids"
                        :options="(options && options['preferredRegion']) || []"
                        @input="handleChangeMultiSelectAsArray"
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Preferred Country(s)</label
                  >
                  <div class="col infoicon-multi-select">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="preferred_country_ids"
                        :value="candidate.preferred_country_ids"
                        :options="
                          (options && options['preferredCountry']) || []
                        "
                        @input="handleChangeMultiSelectAsArray"
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="md-3">
                  <label class="col-lg-12 col-md-12"
                    >Public or Private Profile?</label
                  >
                  <div class="col-lg-12 col-md-12">
                    <RadioButton
                      name="is_private"
                      :value="candidate.is_private"
                      :options="
                        options && options['isPrivate']
                          ? options['isPrivate']
                          : []
                      "
                      label="is_private_user"
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody name="registration-details">
            <h4 class="main-title text-primary pb-3">Registration Details</h4>
            <CRow name="email" class="row mb-3">
              <label class="required col-lg-12 col-md-12">Email</label>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <TextInput
                    name="email"
                    :value="candidate.email"
                    @input="handleInput"
                    @change="handleEmailChange"
                    :error="errors[0]"
                    autocomplete="login-email"
                    :disabled="isCandidateSupplier ? false : true"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <div v-if="!isFullRegister">
              <CRow v-if="!isCandidateSupplier">
              <CCol md="6">
                <CRow name="password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{
                        required: true,
                        password_length: 8,
                        password_strength:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="candidate.password"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="new-password"
                      />
                      <small class="hint">
                        Password format: 8 characters - containing upper & lower
                        case letters, numbers and a special character. No words.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="confirm_password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Confirm Password</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="confirmPassword"
                        :value="candidate.confirmPassword"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="comfirm-password"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            </div>
            <h5 class="text-primary">
              Please Upload your CV and other Documents
            </h5>
            <CRow class="row mb-3 border-top mt-4 pt-3 pb-1">
              <CCol md="6">
                <CRow name="select-document-type" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Select Document Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="document_type"
                      :value="candidate.document_type || 'CV / Resume'"
                      @input="handleChangeSelect"
                      :options="(options && options['document_type']) || []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="upload-document" class="row mb-3">
                  <label class="col-lg-12 col-md-12">Upload Document</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <input
                      name="upload-document"
                      type="file"
                      ref="docfileref"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow
              class="ml-2 mr-2 d-flex align-items-center documentScrolling document-table"
              v-if="uploadFiles.length !== 0"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th class="w-25">Document Type</th>
                    <th>Document Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in uploadFiles" :key="index">
                    <td>
                      {{ data.docType.label
                      }}<span
                        v-if="
                          data.docType &&
                          data.docType.code === 1 &&
                          data.isLatest
                        "
                        class="mx-1 text-primary font-weight-bold fs-12"
                        >(Latest)</span
                      >
                    </td>
                    <td>
                      {{ data.file.name }}
                      <a
                        type="button"
                        v-if="
                          ['PNG', 'JPEG', 'JPG', 'GIF', 'PDF'].includes(
                            data.fileExt
                          )
                        "
                        class="hand-icon action-button"
                        @click="preview(data)"
                      >
                        <i class="fas fa-eye ml-3"></i>
                      </a>
                      <a
                        type="button"
                        v-if="data.isTemp"
                        class="hand-icon action-button"
                        @click="previewTemp(data)"
                      >
                        <i class="fas fa-eye ml-3"></i>
                      </a>
                    </td>
                    <td>
                      <a
                        type="button"
                        class="hand-icon action-button"
                        @click="deleteDocuments(data.file.name, index)"
                      >
                        <i class="fas fa-trash ml-3"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <div>
              <input
                name="isTermPrivacyChecked"
                type="checkbox"
                class="mr-2"
                ref="termPrivacyCheck"
                :checked="isTermPrivacyChecked"
                @change="isTermPrivacyChecked = !isTermPrivacyChecked"
              />
              <span v-if="!isMobile"
                >I have read and agree to the
                <b>
                  <a href="javascript:void(0)" @click="showTermPopup">
                    Terms and Privacy Policy
                  </a>
                </b>
                . By registering your profile and personal details you consent
                and agree to receiving updates and information relating to work
                opportunities. You have the option to <b>unsubscribe</b> at any
                time.
              </span>
              <span v-else>
                I have read and agree to the
                <b>
                  <a href="javascript:void(0)" @click="showTermPopup">
                    Terms and Privacy Policy
                  </a>
                </b>
              </span>
            </div>
          </CCardBody>
        </CCard>
        <CRow style="padding-bottom: 79px !important">
          <CCol v-if="!isCandidateSupplier" sm="12" md="3" lg="3">
            <button
              type="button"
              name="registration-home"
              class="btn rounded-2 px-5 btn-lg float-left btn-outline-primary btn-lg mb-2 w-100"
              @click="goToLogin()"
            >
              Home
            </button>
          </CCol>
          <CCol v-else sm="12" md="3" lg="3">
            <button
              type="button"
              name="registration-home"
              class="btn rounded-2 px-5 btn-lg float-left btn-outline-primary btn-lg mb-2 w-100"
              @click="$router.push('/candidate-list')"
            >
              Cancel
            </button>
          </CCol>
          <CCol sm="12" lg="0" md="3"> </CCol>
          <CCol sm="12" lg="3" md="3">
            <button
              name="registration-cancel"
              class="btn rounded-2 px-5 btn-secondary btn-lg w-100 mb-2"
              type="button"
              @click="resetForm"
            >
              Clear
            </button>
          </CCol>
          <CCol sm="12" lg="3" md="3">
            <button
              name="registration-submit"
              class="btn rounded-2 px-5 btn-primary btn-lg w-100 mb-2"
              type="submit"
              @click="onSubmit"
              :disabled="isSubmitted"
            >
              Submit
              <CSpinner
                v-if="isSubmitted"
                class="spinner-border-sm text-white m-1"
              />
            </button>
          </CCol>
        </CRow>
      </form>
    </ValidationObserver>
    <CToaster v-if="isFileError" :autohide="3000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >File too Big, please select a file less than 2mb</CToast
      >
    </CToaster>

    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="emailConfirmModal.modalTitle"
      :modalColor="emailConfirmModal.modalColor"
      :modalContent="emailConfirmModal.modalContent"
      :isShowPopup="emailConfirmModal.isShowPopup"
      :buttons="emailConfirmModal.buttons"
      :modalCallBack="emailConfirmModalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="termConfirmModal.modalTitle"
      :modalColor="termConfirmModal.modalColor"
      :modalContent="termConfirmModal.modalContent"
      :isShowPopup="termConfirmModal.isShowPopup"
      :buttons="termConfirmModal.buttons"
      :modalCallBack="termmodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="alreadyRegisteredModal.modalTitle"
      :modalColor="alreadyRegisteredModal.modalColor"
      :modalContent="alreadyRegisteredModal.modalContent"
      :isShowPopup="alreadyRegisteredModal.isShowPopup"
      :buttons="alreadyRegisteredModal.buttons"
      :modalCallBack="proceedToMultiRegistration"
      :closeOnBackdrop="false"
      :innerHTML="alreadyRegisteredModal.innerHTML"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <!-- <CampaignModal
      v-if="campaignModal.isShowPopup"
      :isShowPopup="campaignModal.isShowPopup"
      modalColor="primary"
      @modalCallBack="campaignModalCallBack"
      :aspectRatio="2"
      :item="campaignData"
      :buttonLabel="campaignModal.buttonLabel"
    /> -->
    <Modal
      :modalTitle="notifyModal.modalTitle"
      :modalColor="notifyModal.modalColor"
      :modalContent="notifyModal.modalContent"
      :isShowPopup="notifyModal.isShowPopup"
      :buttons="notifyModal.buttons"
      :modalCallBack="notifyModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import registerMixin from "@/containers/CandidateProfile/Registration/registerMixin.js";
export default {
  mixins: [registerMixin],
};
</script>
